import { createSlice } from '@reduxjs/toolkit';

export const prioritiesSlice = createSlice({
  name: 'issuePriorities',
  initialState: [],
  reducers: {
    updatePriorities: (_, action) => action.payload
  }
});

export const { updatePriorities } = prioritiesSlice.actions;

export default prioritiesSlice.reducer;
