import { ApiClient } from './ApiClient';
import { InviteUser, User, UserResponse } from 'types/user';
import { updateToken, updateUser } from 'redux/slices/userSlice';

export const postUserRequest = async function (user: User): Promise<UserResponse> {
  const apiValidData: UserResponse = {
    name: user.userName,
    email: user.userEmail,
    emailVerified: user.emailVerified,
    profilePic: user.userPicture
  };

  const userResponse = await ApiClient.post<UserResponse>('/user', apiValidData);
  return userResponse.data;
};

export const setToken = async (
  dispatch: any,
  getAccessTokenSilently: () => void
): Promise<void> => {
  const token = await getAccessTokenSilently();
  dispatch(updateToken(token));
};

export const setUser = async (dispatch: any, userObj: User): Promise<void> => {
  dispatch(updateUser(userObj));
};

export const postUser = async (dispatch: any, userObj: User): Promise<void> => {
  const userResponse: UserResponse = await postUserRequest(userObj);
  if (userResponse) {
    setUser(dispatch, {
      ...userObj,
      id: userResponse.id,
      organizationId: userResponse.organizationId,
      roleId: userResponse.roleId,
      status: userResponse.status
    });
  }
};

export const inviteUser = async (user: Partial<InviteUser>): Promise<InviteUser> => {
  const response = await ApiClient.post('/user/invite', user);
  return response.data;
};

export const editUser = async (
  userId: string,
  user: Pick<InviteUser, 'email' | 'name'>
): Promise<InviteUser> => {
  const response = await ApiClient.patch(`/user/${userId}`, user);
  return response.data;
};

export const getUsersByOrgId = async (orgId: string): Promise<InviteUser[]> => {
  const response = await ApiClient.get(`/user/${orgId}`);
  return response.data;
};
