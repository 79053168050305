import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import React, { ReactElement } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { router } from 'router/router';
import { GeneralScreenProps } from 'types/general';
import { cleanUserState, updateToken, updateUser } from 'redux/slices/userSlice';
import { AppDispatch, RootState } from 'redux/store';

import 'styles/main.scss';
import { useSelector } from 'react-redux';
import { User } from 'types/user';
import { cleanIssuesState } from 'redux/slices/issuesSlice';
import * as userService from '../../services/UserService';

interface LogoutParams {
  logoutParams: {
    returnTo: string;
  };
}

const renderLoginScreen = (
  props: GeneralScreenProps,
  logout: ({ logoutParams }: LogoutParams) => void,
  dispatch: AppDispatch
): ReactElement => {
  return (
    <div className='App General-page'>
      <header className='Full-page-centered'>
        <img src={props.imgUrl} className='App-logo' data-testid='App-logo' alt='Optimyse' />

        <br />
        <br />

        <div>
          <Button
            variant='outlined'
            onClick={() => {
              logout({
                logoutParams: { returnTo: window.location.origin }
              });
              dispatch(cleanUserState());
              dispatch(cleanIssuesState());
            }}
          >
            Logout
          </Button>

          <br />
          <br />

          <div>
            <p>Some authenticated routes below ...</p>
            <ul style={{ textAlign: 'left', cursor: 'pointer' }}>
              <li onClick={() => router.navigate('/')}>
                <p>/</p>
              </li>
              <li onClick={() => router.navigate('/prioritize')}>/prioritize</li>
              <li onClick={() => router.navigate('/resource-planning')}>/resource-planning</li>
              <li onClick={() => router.navigate('/roadmap')}>/roadmap</li>
              <li onClick={() => router.navigate('/settings')}>/settings</li>
            </ul>
          </div>
        </div>
      </header>
    </div>
  );
};

const LoginScreen = (props: GeneralScreenProps): ReactElement => {
  const { isLoading, loginWithRedirect, logout, isAuthenticated, getAccessTokenSilently } =
    useAuth0();
  const auth0User = useAuth0().user;
  const dispatch = useDispatch();
  const user: User = useSelector((state: RootState) => state.user);

  if (!isLoading && !user.isAuthenticated && !isAuthenticated) {
    loginWithRedirect();
  }

  if (!isLoading && user.isAuthenticated) {
    const userObj = {
      isAuthenticated: isAuthenticated,
      userEmail: user.userEmail,
      userName: user.userName,
      userPicture: user.userPicture,
      emailVerified: user.emailVerified,
      organizationId: user.organizationId || 'empty-org'
    };
    userService.postUser(dispatch, userObj);
    return renderLoginScreen(props, logout, dispatch);
  }

  // Conditions below for SAFARI, firefox issue - auth0 isAuthenticated not set.
  if (!isLoading && !user.isAuthenticated && isAuthenticated) {
    getAccessTokenSilently().then((token) => {
      if (token.length) {
        dispatch(updateToken(token));
      }
      if (auth0User) {
        dispatch(
          updateUser({
            isAuthenticated: isAuthenticated,
            userEmail: auth0User.email,
            userName: auth0User.nickname,
            userPicture: auth0User.picture,
            emailVerified: auth0User.email_verified
          })
        );
      }
    });
  }

  return <></>;
};

export default LoginScreen;
