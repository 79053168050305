import { createSlice } from '@reduxjs/toolkit';
import config from 'config/config'; // TODO: fetch from BE api

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    backendAuthType: 'Basic',
    backendAuthToken: config.backendAuthToken
    // TODO: to be removed soon ^ auth token is board's user email + api token base64 encoded
  },
  reducers: {
    getauth: (state) => {
      state = { ...state };
    }
  }
});

// Action creators are generated for each case reducer function
export const { getauth } = authSlice.actions;

export default authSlice.reducer;
