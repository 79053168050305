import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import Input from 'components/Input/Input';
import IconMenu from 'components/Menu/Menu';
import Button from 'components/Button/Button';
import ResponsiveAppBar from 'components/AppBar/ResponsiveAppBar';
import TabNavigation from 'components/TabNavigation/TabNavigation';
import SideSlider, { SideSliderFooter } from 'components/SideSlider/SideSlider';
import UsersTable, { UserActions, UserSettingAction } from 'components/UsersTable/UsersTable';
import {
  addNewInvitedUser,
  setLoading,
  setUsers,
  updateInvitedUser
} from 'redux/slices/issues-settings/usersSlice';
import { RootState } from 'redux/store';
import { PlusIcon } from 'components/Icons';
import { InviteUser, UserStatus } from 'types/user';
import { editUser, getUsersByOrgId, inviteUser } from 'services/UserService';

import 'styles/main.scss';

const tabLabels = ['General', 'Profile', 'Users', 'Groups', 'Billing'];

const SettingsScreen: React.FC = () => {
  const [selectedUserId, setSelectedUserId] = useState('');
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [activeTab, setActiveTab] = useState(2);
  const [editMode, setEditMode] = useState(false);
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [inviteUserLoading, setInviteUserLoading] = useState(false);

  const dispatch = useDispatch();

  const authenticatedUser = useSelector((state: RootState) => state.user);
  const users = useSelector((state: RootState) => state.settings.users.items);

  const fetchUsersForOrg = async (orgId: string): Promise<void> => {
    dispatch(setLoading(true));

    try {
      const users_ = await getUsersByOrgId(orgId);
      dispatch(setUsers(users_));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Currently we are only showing the users, therefore fetching the users at screen load.
    fetchUsersForOrg(authenticatedUser.organizationId as string);
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number): void => {
    setActiveTab(newValue);
  };

  const handleCloseSlider = (): void => {
    setIsSliderOpen(false);
    setName('');
    setEmail('');
    setEditMode(false);
    setNameError('');
    setEmailError('');
  };

  const updateUser = async (userId: string, user: InviteUser): Promise<void> => {
    try {
      const updatedUser = await inviteUser(user);

      dispatch(updateInvitedUser({ id: userId, updatedUser }));
    } catch (error) {
      console.log(error);
    }
  };

  const createUpdateUserPayload = (user: InviteUser): InviteUser => {
    return {
      id: user.id,
      name: user.name,
      email: user.email,
      emailVerified: user.emailVerified,
      profilePic: user.profilePic,
      status: user.status,
      roleId: user.roleId,
      organizationId: user.organizationId,
      resendInvite: user.resendInvite
    };
  };

  const handleUserSettingActionClick = async (
    action: UserSettingAction,
    user: InviteUser
  ): Promise<void> => {
    switch (action.command) {
      case UserActions.ACTIVATE: {
        const activatedUser: InviteUser = createUpdateUserPayload({
          ...user,
          status: UserStatus.ACTIVE
        });
        await updateUser(user.id, activatedUser);
        return;
      }

      case UserActions.DEACTIVATE: {
        const activatedUser: InviteUser = createUpdateUserPayload({
          ...user,
          status: UserStatus.INACTIVE
        });
        await updateUser(user.id, activatedUser);
        return;
      }

      case UserActions.RESEND_INVITE: {
        const activatedUser: InviteUser = createUpdateUserPayload({
          ...user,
          status: UserStatus.INVITED,
          resendInvite: true
        });
        await updateUser(user.id, activatedUser);
        return;
      }

      case UserActions.EDIT_USER: {
        setSelectedUserId(user.id);
        setEditMode(true);
        setName(user.name);
        setEmail(user.email);
        setIsSliderOpen(true);
        return;
      }

      default:
        return;
    }
  };

  const isFormValid = (): boolean => {
    if (!name) {
      setNameError('Name is required.');
    } else {
      setNameError('');
    }

    if (!email) {
      setEmailError('Email is required.');
    } else {
      setEmailError('');
    }

    if (!name || !email) return false;

    return true;
  };

  const handleInviteUser = async (): Promise<void> => {
    if (!isFormValid()) return;

    try {
      setInviteUserLoading(true);

      if (editMode) {
        const editInvitedUserBody = { email, name };
        const updatedUser = await editUser(selectedUserId, editInvitedUserBody);
        dispatch(updateInvitedUser({ id: updatedUser.id, updatedUser: updatedUser }));
      } else {
        const inviteUserBody = {
          email,
          name,
          organizationId: authenticatedUser.organizationId
        };
        const invitedUser = await inviteUser(inviteUserBody);
        dispatch(addNewInvitedUser(invitedUser));
      }

      handleCloseSlider();
    } catch (error) {
      console.log(error);
    } finally {
      setInviteUserLoading(false);
    }
  };

  return (
    <div>
      <Box className='wrapper'>
        <Box className='sidebar_wrap' data-testid='bread-crumbs'>
          <ResponsiveAppBar user={authenticatedUser} />
        </Box>
        <Box className='content_area '>
          <Box className='inner_content_area'>
            <Typography variant='h2'>Settings</Typography>
            <TabNavigation labels={tabLabels} value={activeTab} onChange={handleTabChange} />
            <SideSlider
              isOpen={isSliderOpen}
              handleCloseSlider={handleCloseSlider}
              title={editMode ? 'Edit User' : 'Invite User'}
            >
              <Input
                label='Name'
                type='text'
                required
                value={name}
                onChange={setName}
                error={nameError.length > 0}
                helperText={nameError}
              />
              <Input
                label='Email'
                type='email'
                required
                value={email}
                onChange={setEmail}
                error={emailError.length > 0}
                helperText={emailError}
              />
              <SideSliderFooter>
                <Button size='large' variant='secondary' onClick={handleCloseSlider}>
                  Cancel
                </Button>
                <Button size='large' onClick={handleInviteUser} disabled={inviteUserLoading}>
                  {editMode ? 'Edit' : 'Send invite'}
                </Button>
              </SideSliderFooter>
            </SideSlider>

            <Grid container spacing={2.5} className='mb-30'>
              <Grid item sm={12} md={12} lg={3.5}>
                <Typography variant='h4'>Users</Typography>
                <Typography>
                  Manage existing users, change roles/permissions. or add new team users.
                </Typography>
              </Grid>
              <Grid item sm={12} md={12} lg={8.5}>
                <Grid container spacing={2.5} className='mb-20'>
                  <Grid item xs={6}>
                    <Typography variant='h4' className='mb-0'>
                      Users
                    </Typography>
                  </Grid>
                  <Grid item xs={6} justifyContent={'flex-end'} display={'flex'}>
                    <Button
                      variant='tertiary'
                      size='small'
                      onClick={() => setIsSliderOpen(true)}
                      icon={<PlusIcon />}
                    >
                      Add user
                    </Button>
                  </Grid>
                </Grid>

                <UsersTable users={users} onUserSettingActionClick={handleUserSettingActionClick} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default SettingsScreen;
