import React from 'react';
import { Navigate, RouteObject, createBrowserRouter } from 'react-router-dom';
import logo from '../assets/optimyse-logo.png';
import GeneralScreen from '../screens/general-screen/GeneralScreen';
import UIScreen from '../screens/ui-screen/UIScreen';
import UIDashboard from '../screens/ui-dashboard/UIDashboard';
import UISettings from '../screens/ui-settings/UISettings';
import UIBacklog from '../screens/ui-backlog/UIBacklog';
import UIGeneral from 'screens/ui-general/UIGeneral';
import UIProfile from 'screens/ui-profile/UIProfile';
import UIGroups from 'screens/ui-groups/UIGroups';
import UIBilling from 'screens/ui-billing/UIBilling';
import UIComponents from '../screens/ui-components/UIComponents';
import PrioritizeScreen from '../screens/prioritize/PrioritizeScreen';
import LoginScreen from '../screens/login/Login';
import Settings from '../screens/settings/SettingsScreen';
import App from 'App';
import { guardedRoute, developmentRoute } from './routeGuard';
import { uiRoutes } from './uiRoutes';
import GuestUserScreen from 'screens/guest-user/GuestUserScreen';

export const AppRoutes: RouteObject[] = [
  {
    path: '/login',
    element: <LoginScreen text='login page' imgUrl={logo} />
  },
  {
    path: '/',
    element: guardedRoute(<App />)
  },
  {
    path: '/dashboard',
    element: guardedRoute(<App />)
  },
  {
    path: '/prioritize',
    element: guardedRoute(<PrioritizeScreen />)
  },
  {
    path: '/resource-planning',
    element: guardedRoute(<GeneralScreen text='Resource planning page' imgUrl={logo} />)
  },
  {
    path: '/roadmap',
    element: guardedRoute(<GeneralScreen text='Roadmap page' imgUrl={logo} />)
  },
  {
    path: '/settings',
    element: guardedRoute(<Settings />)
  },
  {
    path: '/support',
    element: guardedRoute(<GeneralScreen text='Support page' imgUrl={logo} />)
  },
  {
    path: '/guest-user',
    element: guardedRoute(<GuestUserScreen text='Guest page' imgUrl={logo} />, '/guest-user')
  },
  {
    path: '/*',
    element: <Navigate to={'/'} />
  },
  ...uiRoutes
];

export const router = createBrowserRouter(AppRoutes);
