import aes from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8';

export const encryptUserToken = (originalText: string, encryptionLevel?: number): string => {
  encryptionLevel = encryptionLevel || 2;
  const KEY = `${process.env.REACT_APP_OPTIMIYSE_SECRET}`;
  const IV = enc.parse(KEY);

  let hashCopy = aes.encrypt(originalText, KEY, { iv: IV });
  for (let index = 0; index < encryptionLevel; index++) {
    hashCopy = aes.encrypt(hashCopy.toString(), KEY, { iv: IV });
  }

  const hashText = hashCopy.toString();
  return hashText;
};

export const decryptUserToken = (hashText: string, encryptionLevel?: number): string => {
  encryptionLevel = encryptionLevel || 2;
  const KEY = `${process.env.REACT_APP_OPTIMIYSE_SECRET}`;
  const IV = enc.parse(KEY);
  
  let hashCopy = aes.decrypt(hashText, KEY, { iv: IV });
  for (let index = 0; index < encryptionLevel; index++) {
    hashCopy = aes.decrypt(hashCopy.toString(enc), KEY, { iv: IV });
  }

  const decryptText = hashCopy.toString(enc);
  return decryptText;
};
