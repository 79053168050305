import { Issue, IssueResponse, PrioritizeIssuesFilters, UpdateIssuePayload } from 'types/api';
import { statusVariantType } from '../components/StatusChip/StatusChip';
import { DEFAULT_PRIORITY_COLOR } from './settings/PriorityService';
import { ApiClient } from './ApiClient';

type StatusVariants = { [key: string]: statusVariantType };

const STATUS_VARIANT: StatusVariants = {
  'Selected for Development': 'info',
  'In Progress': 'warning',
  Backlog: 'default',
  Done: 'success',
  'In Review': 'category',
  Reopened: 'danger',
  Qa: 'default'
}; // TODO: This is a temporary solution, it will be dynamic

const mapIssueResponse = (issue: Issue): Issue => {
  const { status, progress, timeTracking, reporter, priority } = issue;
  return {
    id: issue.id,
    name: issue.name,
    issueId: issue.issueId,
    key: issue.key,
    priorityId: issue.priorityId,
    description: issue.description ?? '',
    dueDate: issue.dueDate ?? '',
    epicName: issue.epicName ?? 'Uncategorized',
    flagged: issue.flagged,
    status: {
      id: status.id,
      name: status.name,
      iconUrl: status.iconUrl,
      variant: STATUS_VARIANT[status.name] ?? 'default'
    },
    progress: {
      total: issue.progressTotal || 0,
      progress: issue.progressCurrent || 0,
      percent: issue.progressPercent || 0
    },
    timeTracking: {
      originalEstimate: issue.originalEstimateSeconds
        ? `${issue.originalEstimateSeconds / 60 / 60} h`
        : '',
      remainingEstimate: issue.remainingEstimateSeconds
        ? `${issue.remainingEstimateSeconds / 60 / 60} h`
        : ''
    },

    reporter: {
      name: reporter.name,
      profilePic: reporter.profilePic ?? ''
    },
    priority: {
      id: priority.id,
      color: priority.color ?? DEFAULT_PRIORITY_COLOR,
      name: priority.name
    },
    commentCount: issue.commentCount,
    pinnedComment: issue.pinnedComment,
    categories: issue.categories
  };
};

const createQueryParamsFromFilters = (
  filters?: PrioritizeIssuesFilters
): PrioritizeIssuesFilters => {
  if (!filters || Object.keys(filters).length === 0) return {};

  const params: PrioritizeIssuesFilters = {};
  const {
    startEstDate,
    endEstDate,
    startEstTime,
    endEstTime,
    startProgress,
    endProgress,
    priority,
    status,
    category,
    withComments
  } = filters;

  if (startProgress || startProgress === 0) params.startProgress = startProgress;
  if (endProgress || endProgress === 0) params.endProgress = endProgress;
  if (startEstDate) params.startEstDate = startEstDate;
  if (endEstDate) params.endEstDate = endEstDate;
  if (startEstTime) params.startEstTime = startEstTime;
  if (endEstTime) params.endEstTime = endEstTime;
  if (priority?.length) params.priority = priority;
  if (category?.length) params.category = category;
  if (status?.length) params.status = status;
  if (withComments === true || withComments === false) params.withComments = withComments;

  return params;
};

export const getIssues = async (
  pageNo = 1,
  pageLimit = 30,
  filters?: PrioritizeIssuesFilters
): Promise<IssueResponse> => {
  try {
    const endpoint = `/issues?page=${pageNo}&limit=${pageLimit}`;
    const params = createQueryParamsFromFilters(filters);
    const res = await ApiClient.get(endpoint, { params });
    const { issues, page, count } = res.data;

    return {
      issues: issues.map(mapIssueResponse),
      pagination: { page, count, hasMore: issues.length === pageLimit }
    };
  } catch (e: unknown) {
    console.error('GetAllIssues Error: ', e);
    throw e;
  }
};

export const updateIssue = async (id: string, payload: UpdateIssuePayload): Promise<Issue> => {
  try {
    const res = await ApiClient.patch(`/issues/${id}`, payload);
    return mapIssueResponse(res.data);
  } catch (e: unknown) {
    console.error('GetAllIssues Error: ', e);
    throw e;
  }
};
