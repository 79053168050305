import React from 'react';
import UIDashboard from 'screens/ui-dashboard/UIDashboard';
import { developmentRoute } from './routeGuard';
import UISettings from 'screens/ui-settings/UISettings';
import UIGeneral from 'screens/ui-general/UIGeneral';
import UIProfile from 'screens/ui-profile/UIProfile';
import UIGroups from 'screens/ui-groups/UIGroups';
import UIBilling from 'screens/ui-billing/UIBilling';
import UIBacklog from '../screens/ui-backlog/UIBacklog';
import UIComponents from 'screens/ui-components/UIComponents';
import { RouteObject } from 'react-router-dom';
import UIScreen from 'screens/ui-screen/UIScreen';

export const uiRoutes: RouteObject[] = [
  {
    path: '/ui',
    element: developmentRoute(<UIScreen />)
  },
  {
    path: '/ui/dashboard',
    element: developmentRoute(<UIDashboard />)
  },

  {
    path: '/ui/settings',
    element: developmentRoute(<UISettings />)
  },

  {
    path: '/ui/settings/general',
    element: developmentRoute(<UIGeneral />)
  },

  {
    path: '/ui/settings/profile',
    element: developmentRoute(<UIProfile />)
  },

  {
    path: '/ui/settings/groups',
    element: developmentRoute(<UIGroups />)
  },
  {
    path: '/ui/settings/billing',
    element: developmentRoute(<UIBilling />)
  },

  {
    path: '/ui/components',
    element: developmentRoute(<UIComponents />)
  },
  {
    path: '/ui/backlog',
    element: developmentRoute(<UIBacklog />)
  },
];
