import * as React from 'react';
import { useState, ReactElement, MouseEvent, useEffect } from 'react';
import { router } from 'router/router';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { User } from 'types/user';
import styles from 'styles/components/ResponsiveAppBar.module.scss';
import IconMenu from 'components/Menu/Menu';
import { Box } from '@mui/material';
import { Optimyselogo, OptimyseLogoIcon, KebabVIcon, MobileMenuIcon } from '../Icons';
import '../../styles/main.scss';

interface MenuOptions {
  name: string;
  onclick: () => void;
}

interface ResponsiveNavbarProps {
  user: User;
  options?: MenuOptions[];
}

function ResponsiveAppBar(props: ResponsiveNavbarProps): ReactElement {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [isToolbarHidden, setIsToolbarHidden] = useState(window.innerWidth <= 1060);

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>): void => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (): void => {
    setAnchorElUser(null);
  };

  const defaultOptions: MenuOptions[] = [
    { name: 'Profile', onclick: handleCloseUserMenu },
    { name: 'Account', onclick: handleCloseUserMenu },
    { name: 'Dashboard', onclick: handleCloseUserMenu },
    { name: 'Logout', onclick: handleCloseUserMenu }
  ];

  const options = props.options || defaultOptions;

  const toggleToolbar = (): void => {
    setIsToolbarHidden(!isToolbarHidden);
  };

  useEffect(() => {
    if (isToolbarHidden) {
      document.body.classList.add('sidebarShrink');
    } else {
      document.body.classList.remove('sidebarShrink');
    }
  }, [isToolbarHidden]);

  const handleResize = ():void => {
    setIsToolbarHidden(window.innerWidth <= 1060);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box
      className={`${styles.sidebar_wrap} ${isToolbarHidden ? styles.active : ''}`}
      data-testid='bread-crumbs'
    >
      <Box className='overflow_scroll thin_scrollbar'>
        {isToolbarHidden ? (
          <Box className={styles.mobile_logo} sx={{ ml: '16px', pt:'8px', mr: '16px', textAlign: 'center' }}>
            <OptimyseLogoIcon onClick={() => router.navigate('/')} />
          </Box>
        ) : (
          <Box sx={{ ml: '20px', pt:'8px' }}>
            <img src={Optimyselogo} height={20} alt='Optimyse' onClick={() => router.navigate('/')} />
          </Box>
        )}
        {/* <MobileMenuIcon
        onClick={toggleToolbar}
        className={`${styles.toggleButton} ${isToolbarHidden ? styles.active : ''}`}
      ></MobileMenuIcon> */}
        <IconMenu className={styles.side_menu_clone} />
      </Box>
      


      
      <Toolbar disableGutters>
        <Box className={styles.user_dropdown_menu}>
            <IconButton
              onClick={handleOpenUserMenu}
              sx={{ p: 0 }}
              className={styles.inner_btn_wrap}
            >
              <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
              <Tooltip title={props.user.userName}>
                <Avatar
                  alt='Remy Sharp'
                  src={props.user.userPicture}
                  sx={{ width: '32px', height: '32px' }}
                />
                </Tooltip>
                {!isToolbarHidden && (
                  <Typography textAlign='center' className={styles.user_name_txt}>
                    {props.user.userName}
                  </Typography>
                )}
              </Box>
              <KebabVIcon />
            </IconButton>
          <Menu
            sx={{ mt: '45px' }}
            id='menu-appbar'
            anchorEl={anchorElUser}
            className={styles.menu_box_wrap}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            keepMounted
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {options.map((option) => (
              <MenuItem key={option.name} onClick={option.onclick}>
                <Typography textAlign='center'>{option.name}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </Box>
  );
}

export default ResponsiveAppBar;
