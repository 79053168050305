import { createSlice } from '@reduxjs/toolkit';
import config from 'config/config'; // TODO: fetch from BE api

export const organizationSlice = createSlice({
  name: 'organization',
  initialState: {
    organizationURL: config.organization,
    activeBoard: config.activeBoard, // TODO: make multi-board array
    integrationType: 'jira' // TODO: make multi-board array
  },
  reducers: {
    getOrganization: (state) => {
      state = { ...state /* changes: '' */ };
    }
  }
});

// Action creators are generated for each case reducer function
export const { getOrganization } = organizationSlice.actions;

export default organizationSlice.reducer;
