import * as React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { UploadIcon } from 'components/Icons';
import AvatarName from 'components/AvatarName/AvatarName';
import styles from '../../styles/components/FileUpload.module.scss';

interface InputProps {
  label?: string;
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

function FileUpload({ label }: InputProps): React.ReactElement {
  return (
    <Box className={styles.fileupload_wrap}>
      <AvatarName
        className='profile_view'
        alt='Shoaib Ahmed'
        src='/broken-image.jpg'
        width='88px'
        noMargin
      >
        SH
      </AvatarName>
      <Box className={styles.inner_upload}>
        <Box className={styles.upload_info}>Recommended size: 100x100</Box>
        <Button
          component='label'
          className={styles.upload_btn}
          variant='contained'
          startIcon={<UploadIcon />}
        >
          Upload profile picture
          <VisuallyHiddenInput type='file' />
        </Button>
      </Box>
    </Box>
  );
}

export default FileUpload;
