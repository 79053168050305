import * as React from 'react';
import Box from '@mui/material/Box';
import MUICheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import styles from '../../styles/components/Checkbox.module.scss';
import { CheckboxUncheckIcon, CheckboxCheckedIcon } from '../../components/Icons';

interface CheckboxProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  disabled?: boolean;
  value?: string | number;
  className?: string;
}

function Checkbox({ checked, onChange, label, disabled, value, className }: CheckboxProps): React.ReactElement {
  const uniqueId = React.useMemo(() => `checkbox-${Math.random().toString(36).substr(2, 9)}`, []);

  return (
    <Box className={`${styles.checkbox_wrap} ${className || ''}`}>
      <FormControlLabel
        control={
          <MUICheckbox
            checked={checked}
            onChange={onChange}
            id={uniqueId}
            icon={<CheckboxUncheckIcon />}
            checkedIcon={<CheckboxCheckedIcon />}
            disabled={disabled}
            value={value}
            color='warning'
            
          />
        }
        label={label}
        htmlFor={uniqueId}
      />
    </Box>
  );
}

export default Checkbox;
