import { Config } from 'types/general';

const config: Config = {
  organization: process.env.REACT_APP_ORGANIZATION || '',
  activeBoard: process.env.REACT_APP_ACTIVE_BOARD || ['1'], // TODO: make multi-board array
  backendAuthToken: process.env.REACT_APP_AUTH_TOKEN || '',
  auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
  auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  auth0ClientSecret: process.env.REACT_APP_AUTH0_CLIENT_SECRET || ''
};

export default config;
