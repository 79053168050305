import React, { ReactElement, useState } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import persistedStore from './redux/store';
import theme from './styles/theme';
import config from './config/config';
import { router } from './router/router';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@emotion/react';
import { Auth0Provider } from '@auth0/auth0-react';
import { RouterProvider } from 'react-router-dom';
import { CookieConsent } from 'components/CookieConsent';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const { auth0ClientId, auth0Domain } = config;

root.render(
  <Provider store={persistedStore.store}>
    <ThemeProvider theme={theme}>
      <Auth0Provider
        domain={auth0Domain}
        clientId={auth0ClientId}
        authorizationParams={{
          redirect_uri: window.location.origin
        }}
      >
        <RouterProvider router={router} />
        <CookieConsent />
      </Auth0Provider>
    </ThemeProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
